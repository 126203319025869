/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"

import { Layout, Seo, PageSection, classes } from "../../layout"
import { IPhone, FindOutMoreButton, img } from "../../components"

export default function IndexPage(props) {
  const { iphone, heading, heading2, sectionText, paragraph } = classes

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide destination solutions" />

      <h1
        className={heading + " mx-auto mt-20 md:mt-40 max-w-4xl px-6 md:px-0"}
      >
        Trailguide destination solutions
      </h1>

      <div className="mx-auto max-w-4xl my-8 md:my-24 px-6 md:px-0">
        <p className={paragraph}>
          We truly believe that technology can enhance and enrich the outdoor
          experience for visitors to your destination.
        </p>
        <p className={paragraph}>
          By using our solutions for digitalization and immediate accessibility
          you can offer your visitors a premium experience.
        </p>
        <p className={paragraph}>
          Your visitors will be happier, they might stay longer, and they will
          let their friends know about your great destination. Let the visitors
          to your destination act as your marketing office.
        </p>
        <p className={paragraph}>
          Your day to day work managing and presenting your outdoor activity
          offerings will be a lot easier, more efficient and will save you time
          and money.
        </p>
      </div>

      <PageSection>
        <h2 className={heading2}>
          Give your visitors the right tools for exploration
        </h2>
        <div className={sectionText}>
          <div>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Lack of local knowledge</b>
              <br />
              Your employees working in the tourist office might not have a
              complete knowledge of the tours you offer. Visitors might not be
              used to using paper based maps.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Immediate access</b>
              <br />
              Give your visitors easy and immediate access to relevant tour
              information, maps, and GPS navigation through their smart phones.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>No installation and registration</b>
              <br />
              Do not force your visitors to download and install an app from the
              app store, register an account, and have them pay to get access.
            </p>
            <p className={paragraph}>
              <b>Scan a QR code</b>
              <br />
              The app is a modern web app where the only thing your visitors
              need to do is to scan a QR-code or click a link to open the app.
              Even though there is no installation required to use the app, your
              visitors can install it in seconds by adding it to the home
              screen.
            </p>
          </div>
          <IPhone
            alt="An app to find and share outdoor activities"
            src={img("/website/trailguide-iphone-sandbox-icons.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <PageSection>
        <h2 className={heading2}>
          Retain complete control over the content you present
        </h2>
        <div className={sectionText}>
          <div>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Do not dissappoint</b>
              <br />
              Do not let your visitors end up in the ditch or get lost on badly
              marked and overgrown trails. Unhappy visitors might stay shorter
              and let their friends know about their bad experience.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Quality and correctness</b>
              <br />
              Make sure all the information you present to your visitors is
              correct and up to date. With our Trailguide platform you will have
              full control over all content you present on all your digital
              channels.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Translations</b>
              <br />
              Trailguide has automatic translations making it effortless to
              offer the content to multiple nationalities. If you want to keep
              control over your content translations, Trailguide will also let
              you do this.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Custom collections</b>
              <br />
              Do you have a group of mountain bikers visiting your destination?
              Do you also have families wanting to ride nice mountain roads?
              Create custom tour collections for specific groups giving them
              only relevant information needed.
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Brand your activities</b>
              <br />
              Brand the activities you offer as a destination with your logo and
              name to signal these tours are official and quality approved by
              you.
            </p>
          </div>
          <IPhone
            alt="Content branding"
            src={img("/website/trailguide-iphone-branding-trysil.jpeg")}
            className={`hidden sm:block ${iphone}`}
          />
        </div>
      </PageSection>

      <PageSection>
        <h2 className={heading2}>
          Easily integrate tour content on your website
        </h2>
        <div className={sectionText}>
          <div>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Avoid duplicate information</b>
              <br />
              Do you find yourself constantly updating and having to synchronize
              the same tour information in several places?
            </p>
            <p className="text-base sm:text-lg md:text-xl mb-4 md:mb-6">
              <b>Integration module</b>
              <br />
              Integrate your tour content into your websites directly from the
              Trailguide database with our powerful integration module. It is
              simple, flexible and fast.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/plugin"
            >
              Find out more
            </FindOutMoreButton>
          </div>
          <img
            alt="Website integration"
            className="w-1/2 ml-8 hidden md:block"
            src={img("/website/desktop-integration-trysil.jpg")}
          />
        </div>
      </PageSection>

      <PageSection>
        <h2 className={heading2}>
          Make sure you are visible to potential visitors
        </h2>
        <div className={sectionText}>
          <div>
            <p className={paragraph}>
              <b>Attracting users</b>
              <br />
              Trailguide has tens of thousands of users per week looking for
              outdoor activities and new places to explore.
            </p>
            <p className={paragraph}>
              <b>Visibility</b>
              <br />
              Make sure your destination is visible and can be found by users of
              our free Trailguide app and might visit your region. Your
              destination will be clearly visible on the map with your logo and
              attract attention to it and the activities you offer.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/presence"
            >
              Find out more
            </FindOutMoreButton>
            <p className={paragraph}>
              <b>Landing page</b>
              <br />
              With your own landing page on the Trailguide app, potential
              visitors will immediately see what you have to offer. These can
              include tours and activities not found in the public Trailguide
              app.
            </p>
            <FindOutMoreButton
              className="shadow-md sm:border-2 border-green-100"
              to="/products/landingpage"
            >
              Find out more
            </FindOutMoreButton>
          </div>
          <div className="hidden sm:block">
            <IPhone
              alt="Ensure your presence on Trailguide"
              src={img("/website/trailguide-iphone-destination-trysil.png")}
              className={iphone}
            />
          </div>
        </div>
      </PageSection>

      <PageSection>
        <table className="w-full mb-24">
          <tbody>
            <tr>
              <th className="px-4 py-3 text-left bg-gray-300 whitespace-no-wrap">
                Trailguide destination solutions
              </th>
              <th className="px-4 py-3 bg-gray-300"></th>
              <th className="px-4 py-3 bg-gray-300">Small</th>
              <th className="px-4 py-3 bg-gray-300">Medium</th>
              <th className="px-4 py-3 bg-gray-300">Large</th>
            </tr>
            <tr>
              <td className="h-4"></td>
            </tr>
            <tr>
              <td className="px-4 py-1">Your own custom tour app</td>
              <td className="px-4 py-1 text-right whitespace-no-wrap">
                Kr 15.000
              </td>
              <td className="px-4 py-1 text-center"></td>
              <td className="px-4 py-1 text-center"></td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Integration module</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Activity manager</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Collection manager</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Visibility in the Trailguide App</td>
              <td className="px-4 py-1 text-right">Kr 15.000</td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Destination landing page</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center"></td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Activity branding</td>
              <td className="px-4 py-1 text-right">Kr 5.000</td>
              <td className="px-4 py-1 text-center"></td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
              <td className="px-4 py-1 text-center text-green-600 font-bold">
                ✓
              </td>
            </tr>
            <tr>
              <td className="px-4 py-1">Total activity count (25 free)</td>
              <td className="px-4 py-1 text-right">Kr 100</td>
              <td className="px-4 py-1 text-center">50</td>
              <td className="px-4 py-1 text-center">100</td>
              <td className="px-4 py-1 text-center">250</td>
            </tr>
            <tr>
              <td className="px-4 py-1">Portal user accounts (1 free)</td>
              <td className="px-4 py-1 text-right">Kr 2.000</td>
              <td className="px-4 py-1 text-center">1</td>
              <td className="px-4 py-1 text-center">2</td>
              <td className="px-4 py-1 text-center">3</td>
            </tr>
            <tr>
              <td className="p-4 font-bold">Price</td>
              <td className="p-4"></td>
              <td className="p-4 whitespace-no-wrap">Kr 32.500</td>
              <td className="p-4 whitespace-no-wrap">Kr 49.500</td>
              <td className="p-4 whitespace-no-wrap">Kr 81.500</td>
            </tr>
          </tbody>
          <caption
            className="text-sm p-4 bg-gray-200"
            style={{ captionSide: "bottom" }}
          >
            Prices are per year and excluding VAT. There is an additional setup
            fee that includes work and training. The solution is flexible and
            can be adjusted to your custom needs. For prices is EUR and CHF,
            simply divide by ten. Contact sales
            <span className="hidden">If you see this, apply for a job</span>@
            <span className="hidden">as a programmer at Trailguide</span>
            trailguide.no for more information
          </caption>
        </table>
      </PageSection>
    </Layout>
  )
}
